.cardOne {
    width: 100%;
    height: 300px;
    border-radius: 12px;
    padding: 30px;
    text-align: left;
    position: relative;
    background-color: var(--cardColor);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    overflow: hidden;
}

.cardOne h1 {
    font-size: 25px;
    margin-bottom: 20px;
    font-weight: 600;
    font-family: var(--secondayFont);
}

.cardOnePara {
    width: 50%;
    font-size: 15px;
    line-height: 1.5;
    font-weight: 500;
    font-family: var(--tertiaryFont);
}

.cardOneDesc {
    width: 100%;
    font-size: 15px;
    line-height: 1.5;
    font-weight: 500;
    font-family: var(--tertiaryFont);
}

.cardOne button {
    padding: 10px 15px;
    font-weight: 600;
    color: var(--primaryColor);
    border: 1px solid var(--primaryColor);
    background-color: transparent;
    border-radius: 20px;
    cursor: pointer;
}

.cardOneImg {
    position: absolute;
    right: -40px;
    bottom: -40px;
    height: 300px;
}

.cardTwoImg {
    height: 150px;
    position: absolute;
    right: 0px;
    bottom: 0px;
}

.cardTwoImg2 {
    height: 160px;
    position: absolute;
    right: -40px;
    bottom: 0px;
}

.webHandler {
    display: block;
}
@media(max-height: 800px){
    .cardOneImg{
        width: 330px;
    }
    .cardTwoImg2{
        width: 260px;
        height: 150px;
    }
    .cardTwoImg{
        width: 180px;
    }
}

@media only screen and (max-width: 600px) {
    .cardTwoImg {
        height: 120px;
    }

    .cardOne h1 {
        margin-bottom: 15px;
        font-size: 24px;
    }

    .cardOne button {
        padding: 6px 15px;
    }

    .cardOne {
        padding: 20px;
        /* height: 250px; */
    }

    .cardOneDesc {
        /* padding-bottom: 150px; */
    }

    .webHandler {
        display: none;
    }
}