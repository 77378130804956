.guideLineContainerFlex {
    /* height: 400px; */
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    border-radius: 20px;
    margin-top: 25px;
    overflow: hidden;
}

.guideLineContainerFlexLeft img {
    transform: translateY(43px);
}

.guideLineContainerFlexRight {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: start;
    margin-top: 20px;
    padding-top: 20px;
    position: relative;
    z-index: 3;
}

.guideLineContainerFlexRight h1 {
    margin-bottom: 20px;
    font-family: var(--secondayFont);
    font-weight: 400;
    font-size: 50px;
    opacity: 0.8;
    z-index: 3;
}

.guideLineContainerFlexRight p {
    font-family: var(--tertiaryFont);
    line-height: 1.5;
    font-weight: 400;
    font-size: 18px;
    text-align: justify;
    opacity: 0.8;
    z-index: 3;
}

.guidelineOverlay {
    display: none;
}

@media only screen and (max-width: 600px) {
    .guideLineContainerFlexLeft {
        display: none;
    }

    .guideLineContainerFlexRight {
        padding-top: 0%;
        margin-top: 0px;
    }

    .guideLineContainerFlexRight h1 {
        font-size: 30px;
        margin-bottom: 10px;
        font-family: var(--tertiaryFont);
    }

    .guideLineContainerFlexRight p {
        line-height: 1.7;
        font-size: 14px;
    }

    .guidelineOverlay {
        display: block;
        width: 800px;
        position: absolute;
        bottom: -25px;
        left: -25px;
        z-index: 0;
    }

    .guideLineContainerFlex {
        background-color: var(--primaryColor);
        color: var(--white);
        border-radius: 20px;
        margin-top: 25px;
        overflow: hidden;
    }

}