.footer {
    height: 280px;
    margin-bottom: 10px;
}

.innerFooter {
    width: 100%;
    height: 100%;
    padding: 40px 30px;
    padding-top: 20px;
    padding-right: 15px;
    background-color: var(--primaryColor);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 15px;
}

.foot1 {
    width: 18%;
    text-align: start;
}

.foot1 h1 {
    font-size: 15px;
    color: var(--white);
    font-weight: 600;
    opacity: 0.6;
    margin-bottom: 20px;
}

.foot1 p {
    font-size: 13px;
    color: var(--white);
    font-weight: 400;
    margin-bottom: 10px;
}

.foot2 {
    width: 18%;
    text-align: start;
}

.foot2 h1 {
    font-size: 15px;
    color: var(--white);
    font-weight: 600;
    opacity: 0.6;
    margin-bottom: 20px;
}

.foot2 p {
    font-size: 13px;
    color: var(--white);
    font-weight: 400;
    margin-bottom: 10px;
}

.footGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.foot3 {
    width: 30%;
    text-align: start;
}

.foot3 h1 {
    font-size: 15px;
    color: var(--white);
    font-weight: 600;
    opacity: 0.6;
    margin-bottom: 20px;
}

.foot3 p {
    font-size: 13px;
    color: var(--white);
    font-weight: 400;
    margin-bottom: 10px;
}

.foot4 {
    height: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    font-size: 20px;
    line-height: 2;
    color: var(--white);

}

.footer h3 {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 400;
}

@media only screen and (max-width: 600px) {
    .innerFooter {
        flex-direction: column;
        background-color: var(--white);
    }

    .foot1 {
        width: 100%;
    }

    .foot2 {
        width: 100%;
    }

    .foot3 {
        width: 100%;
    }

    .foot4 {
        width: 70%;
        height: 40px;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 35px;
    }

    .footer {
        width: 100%;
        height: 100%;
        line-height: 2;
        margin-top: 30px;
    }

    .foot1 h1 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .foot3 h1 {
        margin-top: 25px;
        margin-bottom: 10px;
    }

    .foot2 h1 {
        margin-top: 25px;
        margin-bottom: 10px;
    }

    .footer h3 {
        /* margin-bottom: 15px; */
        background-color: var(--primaryColor);
        color: var(--white);
        font-weight: 400;
        margin-top: 0px;
        padding: 10px;
    }
    .foot3 a{
        text-decoration: none;
        color: black !important;
    }
    .foot4{
        background-color: var(--primaryColor);
        padding: 15px;
    }
    .foot4 a{
        text-decoration: none;
        color: var(--white) !important;
    }
    .foot1 h1,
    .foot2 h1,
    .foot3 h1,
    .foot1 p,
    .foot2 p,
    .foot3 p,
    .foot4 {
        color: black !important;
    }
}