.diseaseContainer {
    margin-top: 50px;
}

.diseaseContainerHead {
    width: 62%;
    margin-left: auto;
    margin-right: auto;
    padding: 30px;
}

.diseaseContainerHead h1 {
    font-size: 50px;
    font-weight: 500;
    font-family: var(--secondayFont);
    margin-bottom: 15px;
}

/* .diseaseContainerHead p {} */

.diseaseContainerFlex {
    /* min-height: 530px; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.diseaseContainerFlexLeft {
    width: 50%;
    padding: 25px;
}

.diseaseContainerFlexLeftHead {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.diseaseTabInactive {
    padding: 15px 20px;
    font-size: 20px;
    font-family: var(--secondayFont);
    font-weight: 500;
    border-bottom: 2px solid rgb(210, 208, 208);
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.diseaseTabActive {
    padding: 15px;
    font-size: 20px;
    font-family: var(--secondayFont);
    font-weight: 500;
    border-bottom: 2px solid var(--primaryColor);
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.diseaseInactive {
    font-size: 14px;
    padding: 3px 15px;
    border-radius: 20px;
    font-weight: 500;
    margin-bottom: 15px;
    font-family: var(--primaryFont);
    background-color: var(--diseaseColor);
    cursor: pointer;
}

.diseaseActive {
    font-size: 14px;
    padding: 3px 15px;
    border-radius: 20px;
    font-weight: 500;
    margin-bottom: 15px;
    color: var(--white);
    font-family: var(--primaryFont);
    background-color: var(--primaryColor);
    cursor: pointer;
}

.diseaseContainerFlexLeftBody {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    line-height: 1.5;
}

.diseaseContainerFlexRight {
    width: 50%;
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: start;
    border-left: 1px solid #333333;
}


.diseaseContainerFlexRight h1 {
    font-size: 40px;
    font-family: var(--secondayFont);
    font-weight: 500;
    margin-bottom: 20px;
}

.diseaseContainerFlexRight p {
    line-height: 1.5;
    text-align: justify;
    font-family: var(--tertiaryFont);
}

@media only screen and (max-width: 600px) {
    .diseaseContainerHead {
        padding: 0px;
        width: 100%;
    }

    .diseaseContainerHead h1 {
        font-size: 30px;
        font-weight: 400;
        font-family: var(--tertiaryFont);
    }

    .diseaseContainerHead p {
        line-height: 1.5;
        font-size: 16px;
        font-weight: 400;
        font-family: var(--tertiaryFont);
    }

    .diseaseContainerFlexRight {
        display: none;
    }

    .diseaseContainerFlexLeft {
        width: 100%;
        padding: 0px;
    }

    .diseaseTabActive {
        font-size: 16px;
        padding-left: 5%;
        padding-right: 5%;
        text-align: start;
    }

    .diseaseTabInactive {
        font-size: 16px;
        padding-left: 5%;
        padding-right: 5%;
        text-align: start;

    }

    .diseaseContainerFlex {
        margin-top: 20px;
    }

    #overlay1 {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 2;
        cursor: pointer;
    }

    .diseaseOverlay {
        position: fixed;
        width: 85%;
        border-radius: 20px;
        /* height: 50%; */
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: var(--white);
        z-index: 10;
        cursor: pointer;
        padding: 15px 15px;
    }

    .diseaseOverlayHead {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
    }

    .diseaseOverlayBody {
        width: 100%;
        height: 270px;
        overflow-y: scroll;
    }

    .diseaseOverlayHead h1 {
        font-size: 20px;
        font-weight: 500;
        font-style: var(--tertiaryFont);
        margin-bottom: 15px;
    }

    .diseaseOverlay p {
        font-size: 14px;
        font-family: var(--tertiaryFont);
        text-align: start;
        line-height: 1.6;
        opacity: 0.7;
    }

    .close {
        font-size: 27px;
        position: absolute;
        top: 0%;
        right: -2%;
        color: var(--primaryColor);
    }
}