body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}

:root {
  --white: #ffffff;
  --primaryColor: #29505E;
  --primaryFont: "nunito";
  --secondayFont: "Source Serif 4, serif";
  --tertiaryFont: "Quicksand", sans-serif;
  --secondaryColor: #f4f4f5;
  --diseaseColor: #FAFAFA;
  --borderColor: #dbdbdb;
  --cardColor: #EFEFEF;
  --testFont1: "Hanken Grotesk", sans-serif;
  --testFont2: "Inter", sans-serif;
  --testFont3: "Poppins", sans-serif;
}

.sectionWidth {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.border {
  border-radius: 10px;
  height: 87%;
  background-color: var(--cardColor);
}

.nunito {
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}

.marginTop50 {
  margin-top: 50px;
}

.marginTop20 {
  margin-top: 20px;
}

.hidden {
  overflow: hidden;
}


.webHandler {
  display: block !important;
}

.mobileHandler {
  display: none !important;
}

@media only screen and (max-width: 600px) {
  .webHandler {
    display: none !important;
  }

  .mobileHandler {
    display: block !important;
  }

  .sectionWidth {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}