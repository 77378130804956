.navBar {
  width: 80%;
  height: 80px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 10%;
  z-index: 10;
}
.highlight{
    color: black;
    font-weight: bold;
}

.statustext {
  margin-top: 10px;
  color: green;
  font-weight: 600;
}

.navBar img {
  margin-top: 10px;
  height: 90px;
}

.menu {
  width: 60%;
  display: flex;
  margin-left: 120px;
}

.menuItemDisable {
  font-size: 18px;
  font-weight: 400;
  padding: 10px 20px;
  border-bottom: 2px solid var(--borderColor);
}

.menuItems {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: var(--primaryColor);
  border-radius: 50%;
  position: absolute;
  left: 0;
}

.menuItemActive {
  font-size: 18px;
  font-weight: 400;
  padding: 10px 20px;
  list-style: disc var(--primaryColor);
  border-bottom: 2px solid var(--primaryColor);
}

.navBar button {
  font-size: 18px;
  padding: 10px 15px;
  color: var(--white);
  border-radius: 30px;
  background-color: var(--primaryColor);
  /* background-color: transparent; */
  border: 1px solid var(--white);
}

/* overlay */
.scheduleOverlay {
  position: fixed;
  width: 25%;
  border-radius: 15px;
  height: 95%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translate(150%, 3%);
  background-color: white;
  z-index: 11;
  cursor: pointer;
}

#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  cursor: pointer;
}

.scheduleOverlayHead {
  background-color: var(--primaryColor);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.scheduleOverlayHead h1 {
  font-size: 16px;
  color: var(--white);
  font-weight: 500;
  margin-left: 15px;
}

.scheduleOverlayHeadLeft {
  display: flex;
  align-items: center;
  justify-content: center;
}

.IconClose {
  color: var(--white);
  font-size: 20px;
}

.scheduleOverlayBody {
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  height: 90%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}

.scheduleOverlayBody img {
  height: 60px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scheduleOverlayBodyContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 15px;
}

.scheduleOverlayBodyContent h2 {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
  opacity: 0.5;
}

.scheduleOverlayBodyContent input {
  width: 100%;
  padding: 4px;
}

.scheduleOverlayBodyContent select {
  width: 100%;
  padding: 4px;
}

.scheduleOverlayBodyContent textarea {
  padding: 4px;
  width: 100%;
}

.scheduleOverlayBody button {
  width: 100%;
  padding: 8px;
  background-color: var(--primaryColor);
  color: var(--white);
  border-radius: 8px;
  margin-top: 25px;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 25px;
}

.NavIcons {
  font-size: 22px;
  color: var(--primaryColor);
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

#mobNav1 {
  display: none;
}

@media only screen and (max-width: 600px) {
  /* .navBar {
            display: none;
        } */

  .navBarMob {
    padding: 10px 0px;
    padding-top: 0px;
  }

  .navBarMob img {
    height: 60px;
  }

  .scheduleOverlayBody{
    gap: 10px;
  }
  .scheduleOverlayBodyContent{
    margin-top: 20px;
    margin-bottom: 0px;
    margin-top: 0px;
    
  }
  .scheduleOverlayBodyContent h2{
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .scheduleOverlayBodyContent select{
    margin-bottom: 8px;
    margin-bottom: 5px;
    margin-top: 0px;  
  }
  .scheduleOverlayBodyContent input{
    margin-bottom: 8px;
    margin-bottom: 5px;
    margin-top: 0px;
  }
  .scheduleOverlayBodyContent textarea{
    margin-top: 6px;
    margin-bottom: 5px;
  }

  .navBarMobBody {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .navMobLogo {
    font-size: 25px;
    font-weight: 900;
  }

  .scheduleOverlay {
    width: 90%;
    height: 100%;
    transform: translate(6%, 0%);
  }

  .scheduleOverlayBody img {
    height: 60px;
    margin-top: 0px;
  }

  .scheduleOverlayBodyContent input {
    padding: 6px;
  }

  .mobNavbar {
    position: fixed;
    width: 70%;
    border-radius: 15px;
    height: 100%;
    top: 50%;
    left: 65%;
    transform: translate(50%, -50%);
    background-color: white;
    z-index: 20;
    cursor: pointer;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: 1s;
  }

  .mobNavbarActive {
    position: fixed;
    width: 70%;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    height: 100%;
    top: 50%;
    left: 65%;
    transform: translate(-50%, -50%);
    background-color: white;
    z-index: 20;
    cursor: pointer;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: 1s;
  }

  #mobNav1 {
    display: flex;
  }

  .mobNavbarTop {
    width: 100%;
  }

  .mobNavbarImg {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 50px;
  }

  .mobNavbarImg img {
    height: 65px;
  }

  .mobNavbarIcon {
    font-size: 25px;
    color: var(--primaryColor);
  }

  .mobNavbarMenu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .mobNavbarMenuInactive {
    width: 100%;
    border-radius: 8px;
    text-align: start;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    padding: 8px;
    margin-bottom: 5px;
    color: var(--primaryColor);
  }

  .mobNavbarMenuActive {
    width: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    text-align: start;
    font-size: 16px;
    font-weight: 500;
    padding: 8px;
    margin-bottom: 5px;
    color: var(--primaryColor);
    background-color: var(--borderColor);
  }

  .mobMenuIcon {
    font-size: 18px;
    margin-right: 10px;
  }

  .mobNavbarBottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .mobNavbarBottom p {
    font-size: 14px;
    opacity: 0.5;
    text-align: justify;
  }

  .mobNavbarBottom button {
    /* width: 70%; */
    padding: 8px;
    background-color: var(--primaryColor);
    color: var(--white);
    border-radius: 8px;
    margin-top: 25px;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 25px;
    animation: scaleani 0.7s ease-in-out alternate infinite;
    margin-right: 20px;
}
@keyframes scaleani {
    0% {
        transform: scale(1);
    }
    100%{
        transform: scale(1.1);
    }
}
  
  .btnandIcon{
    width: 100%;
    display: flex;
    color: white;
    align-items: center;
    justify-content: space-between;

  }
  .btnandIcon a{
    background-color: var(--primaryColor);
    border-radius: 40px;
    padding: 8px 10px;

  }
  .NavIcons a{
    color: var(--primaryColor) !important;
  }

  .dot {
    display: none;
  }
}
