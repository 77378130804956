.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #29505E;
  z-index: 2;
  cursor: pointer;
}

.requestCall {
  position: fixed;
  right: 1.5%;
  bottom: 5%;
  z-index: 20;
  cursor: pointer;
}

.requestCallText {
  font-size: 16px;
  font-weight: 400;
  font-family: var(--tertiaryFont);
  background-color: var(--primaryColor);
  padding: 10px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: var(--white);
}

.overlayIcon {
  position: absolute;
  top: -475%;
  right: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.whatsAppIcon,
.callIcon,
.formIcon {
  font-size: 45px;
  color: var(--white);
  background-color: var(--primaryColor);
  padding: 10px;
  margin-top: 10px;
  border-radius: 50%;
}

@media only screen and (max-width: 600px) {
  .requestCall {
    right: 5%;
  }
}