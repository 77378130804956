.TestimonialCompBody {
    padding: 20px;
    padding-bottom: 0px;
    border-radius: 8px;
    background-color: var(--cardColor);
    text-align: start;
    margin-bottom: 15px;
    position: relative !important;
}

.TestimonialCompBody h1 {
    font-size: 20px;
    font-weight: 500;
    font-family: var(--secondayFont);
    margin-bottom: 15px;
}

.TestimonialCompBody p {
    font-size: 16px;
    line-height: 1.7;
    font-weight: 400;
    font-family: var(--tertiaryFont);
    margin-bottom: 15px;
}

.testimonialImg {
    height: 150px;
    /* position: absolute; */
}

.testimonialImgSection {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}