

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .swiper-slide img {
    display: block;
    width: 100%;
    height: 88%;
    object-fit: cover;
    border-radius: 20px;
} */

.swiper-slide video {
    display: block;
    width: 100%;
    height: 520px;
    object-fit: cover;
    border-radius: 20px;
}

.homeSwiper {
    margin-top: 50px;
    margin-bottom: 50px;
}

.homeSwiperHead {
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.homeSwiperHead h1 {
    font-size: 50px;

    font-family: var();
}

.homeSwiperHead p {
    width: 60%;
    font-size: 16px;
    text-align: left;
    margin-top: 25px;
}

.swiperPosition {
    width: 100%;
    height: 100%;
    position: relative;
}

.swiperPlay {
    position: absolute;
    z-index: 5;
    font-size: 50px;
    color: var(--white);
}

#overlaySwiper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 2;
    cursor: pointer;
    border-radius: 20px;
}

@media only screen and (max-width: 600px) {
    .homeSwiper {
        margin-top: 50px;
    }

    .swiper-slide video {
        height: 200px !important;
        border-radius: 15px;
    }

    .swiper-slide img {
        height: 200px !important;
        border-radius: 15px;
    }

    .swiper-slide.swiper-slide-active {
        /* height: 280px; */
        margin-top: 7%;
    }

}