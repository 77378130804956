.wholethree{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}
.subthree{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
}
.subthree h2{
    font-family: var(--secondayFont);
    font-weight: 400;
    font-size: 55px;
    line-height: 71.5px;
    text-align: left;
}
.subthree p{
    width: 70%;
    font-size: 18px;
    font-weight: 500;
    line-height: 23.4px;
    text-align: justify;
    font-family: var(--secondayFont);
}
.griddiv{
    /* width: 100% !important; */
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 40px;
    /* overflow-x:hidden !important; */
}
.carddesign{
    width: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* background-color:#FFD980; */
    position: relative;
    /* padding: 15px; */
    border-radius: 20px;
    min-height:400px;
    margin-bottom: 40px;

}
.carddesign img{
    width: 100%;
    height: 400px;
    border-radius: 20px;
}
.quote{
    font-size: 60px;
    color: white;
    opacity: 0.4;
    text-align: left !important;
    position: absolute;
    top: 30px;
}
.carddesign p{
    width: 100% !important;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: var(--testFont3);
    font-size: 23.5px !important;
    font-weight: 700 !important;
    line-height: 30.98px !important;
    color: white;
    margin-top: 30px;
}
.persondetails{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px;
    gap: 10px;
}
.persondetails img{
    width:60px;
    height: 60px;
    border-radius: 40px;
}
.personname{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.personname p{
    font-size: 20px !important;
    font-family: var(--testFont3) !important;
    color:#F78316;
    font-weight: 400 !important;
    padding: 0px !important;
    margin: 0px !important;
}
.personname span{
    font-size: 17px !important;
    font-family: var(--testFont3) !important;
    color:#F78316;
    font-weight: 400 !important;
    text-align: left;
}
.bgclr{
    background-color:#4592FF;
}
.bgclr1{
    background-color:#262D33;
}
.personname1{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.personname1 p{
    font-size: 20px !important;
    font-family: var(--testFont3) !important;
    color:white;
    font-weight: 400 !important;
    padding: 0px !important;
    margin: 0px !important;
}
.personname1 span{
    font-size: 17px !important;
    font-family: var(--testFont3) !important;
    color:white;
    font-weight: 400 !important;
    text-align: left;
}
.personname2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.personname2 p{
    font-size: 20px !important;
    font-family: var(--testFont3) !important;
    color:white;
    font-weight: 400 !important;
    padding: 0px !important;
    margin: 0px !important;
}
.personname2 span{
    font-size: 17px !important;
    font-family: var(--testFont3) !important;
    color:white;
    font-weight: 400 !important;
    text-align: left;
}
.slide{
    width:300px !important;
    overflow:  hidden !important;
}

.mySwiper{
    width: 100% !important;
}
.swiper-wrapper{
    height: auto !important;
}
.slide{
    border-radius: 20px !important;
}
.marginClass{
    margin-top: 20px;
}

@media(max-width:600px){
    .subthree{
        align-items: center;
        width: 90%;
    }
    .subthree h2{
        font-family: var(--secondayFont);
        font-weight: 400;
        font-size: 35px;
        line-height: 35px;
        text-align: left;
    }
    .subthree p{
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        line-height: 23.4px;
        text-align: justify;
        font-family: var(--secondayFont);
    }
    .griddiv{
        width: 100% !important;
        display: grid;
        grid-template-columns: repeat(1,1fr);
        gap: 40px;
        margin-bottom: 30px;
        overflow-x:hidden !important;
    }
    .swiper-wrapper{
        height: auto !important;
        border-radius:20px;
    }
    .slide{
        width: 240px !important;
        border-radius: 20px !important;
        margin-right:20px !important;
        margin-left: 10px !important;
        /* height: 300px !important; */
    }
    .carddesign{
        min-height: 0px;

    }
    .carddesign p{
        font-size: 20px;
    }
    .carddesign img{
        height: 300px !important;
    }
    .persondetails img{
        width: 40px !important;
        height: 40px !important;
    }
    .personname span{
        font-size:14px !important;
    }
    .personname1 span{
        font-size:14px !important;
    }
    .personname2 span{
        font-size:14px !important;
    }
}