.homePentGrid {
    margin-bottom: 50px;
    margin-top: 50px;
}

.bentoGrid {
    width: 100%;
    /* max-width: 960px; */
    /* height: 700px; */
    display: grid;
    grid-auto-rows: minmax(100px, auto);
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
}

/* bentGrid1 */

.bentGrid1 {
    /* height: 300px; */
    grid-column: 1/2;
    grid-row: 1/4;
    background-color: var(--cardColor);
    border-radius: 10px;
    position: relative;
    padding: 15px;
    text-align: left;
}

.bentGrid1 h1 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
}

.bentGrid1 p {
    /* width: 76%; */
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 15px;
    line-height: 1.5;
    font-family: var(--tertiaryFont);
}

.bentGridImg {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

}

.bentGridImg img {
    height: 200px;
    position: absolute;
    right: 0px;
    bottom: 0px;
}

/* bentGrid2 */

.bentGrid2 {
    grid-column: 2/4;
    grid-row: 1/3;
    background-color: var(--cardColor);
    border-radius: 10px;
    /* height: 200px; */
    text-align: start;
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.bentGrid2 h1 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
}

.bentGrid2 p {
    font-family: var(--tertiaryFont);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
}

.bentGrid2Icon {
    color: #FEC006;
}

/* bentGrid3 */

.bentGrid3 {
    /* height: 300px; */
    grid-column: 4/5;
    grid-row: 1/4;
    background-color: var(--cardColor);
    border-radius: 10px;
    position: relative;
    padding: 15px;
    text-align: left;
}

.bentGrid3 h1 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
}

.bentGrid3 p {
    /* width: 76%; */
    font-family: var(--tertiaryFont);
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 15px;
    line-height: 1.5;
}

.bentGridImg img {
    height: 200px;
    /* position: absolute;
        right: 0px;
        bottom: 0px; */
}

/* bentGrid4 */

.bentGrid4 {
    grid-column: 1/2;
    grid-row: 4/6;
    background-color: var(--cardColor);
    border-radius: 10px;
    /* height: 200px; */
    text-align: start;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

}

.bentGrid4 h1 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}

.bentGrid4 p {
    font-family: var(--tertiaryFont);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 10px;
}

/* bentGrid5 */

.bentGrid5 {
    grid-column: 2/4;
    grid-row: 3/6;
    /* background-color: var(--cardColor); */
    border-radius: 10px;
    /* height: 300px; */
    padding: 20px;
}

.bentGrid5 h1 {
    font-size: 50px;
    color: var(--primaryColor);
    font-family: var(--primaryFont);
    font-weight: 900;
}

.bentGrid5 p {
    font-family: var(--tertiaryFont);
    font-size: 16px;
    font-family: var(--primaryFont);
    font-weight: 400;
    line-height: 1.5;
    /* margin-bottom: 10px; */
}

/* bentGrid6 */

.bentGrid6 {
    grid-column: 4/5;
    grid-row: 4/8;
    background-color: var(--cardColor);
    border-radius: 10px;
    text-align: start;
    /* padding: 30px 20px;
        padding-top: 0px; */
    /* height: 410px; */
    position: relative;
}

.bentGrid6 h1 {
    font-size: 16px;
    font-weight: 500;
    padding: 20px 20px;
    border-radius: 10px;
}

.bentGrid6 p {
    /* width: 76%; */
    font-size: 16px;
    font-family: var(--tertiaryFont);
    font-weight: 400;
    margin-bottom: 10px;
    line-height: 1.5;
    padding: 0px 20px;
}

.bentGridImg img {
    height: 150px;
    /* position: absolute;
        right: 0px;
        bottom: 0px; */
}

.review {
    width: 100%;
    height: 60px;
    position: relative;
    background-color: var(--white);
    position: relative;
}

.reviewImg1 {
    height: 45px !important;
    position: absolute;
    border-radius: 50%;
    top: 10%;
    left: 5%;
    object-fit: cover;
    border: 3px solid var(--white);
    /* position: relative; */
}

.reviewImg2 {
    height: 45px !important;
    position: absolute;
    border-radius: 50%;
    top: 10%;
    left: 15%;
    object-fit: cover;
    border: 3px solid var(--white);
}

.reviewImg3 {
    height: 45px !important;
    position: absolute;
    border-radius: 50%;
    top: 10%;
    left: 25%;
    object-fit: cover;
    border: 3px solid var(--white);
}

.reviewImg4 {
    height: 45px !important;
    position: absolute;
    border-radius: 50%;
    top: 10%;
    left: 35%;
    object-fit: cover;
    border: 3px solid var(--white);
}

.reviewImg5 {
    height: 45px !important;
    position: absolute;
    border-radius: 50%;
    top: 10%;
    left: 45%;
    object-fit: cover;
    border: 3px solid var(--white);
}

.reviewImg6 {
    height: 45px !important;
    position: absolute;
    border-radius: 50%;
    top: 10%;
    left: 55%;
    object-fit: cover;
    border: 3px solid var(--white);
}

.border {
    border-radius: 10px;
    height: 87%;
    background-color: var(--cardColor);
}

/* bentGrid7 */

.bentGrid7 {
    grid-column: 1/3;
    grid-row: 6/8;
    background-color: var(--cardColor);
    border-radius: 10px;
    /* height: 200px; */
    padding: 20px 20px;
    text-align: start;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.bentGrid7 h1 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
}

.bentGrid7 p {
    font-family: var(--tertiaryFont);
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
    line-height: 1.5;

}

/* bentGrid8 */

.bentGrid8 {
    grid-column: 3/4;
    grid-row: 6/8;
    background-color: var(--cardColor);
    border-radius: 10px;
    /* height: 200px; */
    text-align: start;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.bentGrid8 h1 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}

.bentGrid8 p {
    font-family: var(--tertiaryFont);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 10px;
    /* line-height: 20px; */
}

.reviewHover {
    width: 70%;
    position: absolute;
    font-size: 14px;
    font-weight: 400;
    color: var(--white);
    padding: 15px !important;
    top: -150%;
    left: 10%;
    border-radius: 20px;
    background-color: var(--primaryColor);
}

.arrow {
    position: absolute;
    top: -75%;
    left: 10%;
}

.reviewHover2 {
    width: 70%;
    position: absolute;
    font-size: 14px;
    font-weight: 400;
    color: var(--white);
    padding: 15px !important;
    top: -150%;
    left: 20%;
    border-radius: 20px;
    background-color: var(--primaryColor);
}

.arrow2 {
    position: absolute;
    top: -75%;
    left: 20%;
}

.reviewHover3 {
    width: 70%;
    position: absolute;
    font-size: 14px;
    font-weight: 400;
    color: var(--white);
    padding: 15px !important;
    top: -150%;
    left: 30%;
    border-radius: 20px;
    background-color: var(--primaryColor);
}

.arrow3 {
    position: absolute;
    top: -75%;
    left: 30%;
}

.reviewHover4 {
    width: 70%;
    position: absolute;
    font-size: 14px;
    font-weight: 400;
    color: var(--white);
    padding: 15px !important;
    top: -150%;
    left: 40%;
    border-radius: 20px;
    background-color: var(--primaryColor);
}

.arrow4 {
    position: absolute;
    top: -75%;
    left: 40%;
}

.reviewHover5 {
    width: 70%;
    position: absolute;
    font-size: 14px;
    font-weight: 400;
    color: var(--white);
    padding: 15px !important;
    top: -150%;
    left: 50%;
    border-radius: 20px;
    background-color: var(--primaryColor);
}

.arrow5 {
    position: absolute;
    top: -75%;
    left: 50%;
}

.reviewHover6 {
    width: 70%;
    position: absolute;
    font-size: 14px;
    font-weight: 400;
    color: var(--white);
    padding: 15px !important;
    top: -150%;
    left: 60%;
    border-radius: 20px;
    background-color: var(--primaryColor);
}

.arrow6 {
    position: absolute;
    top: -75%;
    left: 60%;
}