.homebanner {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    /* background-color: var(--primaryColor); */
}

.homebannerFlex {
    width: 100%;
    height: 83%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.video-container {
    position: relative;
    width: 100%;
    max-width: 100%;
}

.video-container video {
    width: 100%;
    height: auto;
}

.homebannerFlexLeft {
    width: 70%;
    height: 100%;
}

.homebannerFlexRight {
    width: 30%;
    height: 100%;
    background-color: var(--primaryColor);
    position: relative;

}

.homebannerFlexRightImg {
    width: 300px;
    height: 480px;
    position: absolute;
    left: -30%;
    top: 17%;
    /* position: relative; */
}

.homebannerFlexRightImg video {
    width: 100% !important;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.play {
    width: 30px;
    height: 0px;
    position: absolute;
    left: 0%;
}

.pause {
    position: absolute;
    color: var(--white);
    font-size: 43px;
    bottom: 4%;
    right: 4%;
    z-index: 6;
}

.bannerShade {
    width: 500px;
    height: 1000px;
    background-color: #23404B66;
    position: absolute;
    left: 140%;
    top: -72%;
    transform: rotate(-28deg);
}

.Social {
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* gap: 30px; */
    font-size: 20px;
    position: absolute;
    bottom: 3%;
    right: 7%;
    color: var(--white);
    /* background-color: var(--primaryColor); */
    /* background-color: blue; */
}
a{
    color: var(--white) !important;
    text-decoration: none;
}

.homebannerFlexLeftInner {
    width: 85%;
    margin-left: auto;
    /* margin-right: auto; */
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 50px;
}

.homebannerFlexLeftHead {
    width: 58%;
    text-align: start;
}

.homebannerFlexLeftHead h1 {
    font-size: 50px;
    font-weight: 900;
    /* margin-top: 50px; */
    font-family: var(--primaryFont);
}

.homebannerFlexLeftInner p {
    width: 70%;
    font-size: 17px;
    font-weight: 500;
    margin-top: 35px;
    margin-bottom: 35px;
    text-align: start;
    line-height: 1.5;
    text-align: justify;
    /* font-family: var(--tertiaryFont); */
    /* font-family: var(--testFont1); */
    /* font-family: var(--testFont2); */
    font-family: var(--testFont3);
}

.headSpan {
    color: var(--primaryColor);
    margin-right: 10px;
    margin-left: 87px;
    font-size: 35px;

}

.certificateImg {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.certificateImg img {
    height: 25px;
    /* width: 25px; */
    margin-right: 10px;
    margin-bottom: 20px;
    /* object-fit: cover; */
}

.certificate h2 {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;
    font-family: var(--tertiaryFont);
}

.homebannerFlexLeftFooter {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 25px 200px;
    padding-left: 0px;
    padding-bottom: 0%;
    text-align: start;
    margin-top: 35px;
}

.homebannerFlexLeftFoot {
    height: 100%;
    margin-right: 25px;
    padding-right: 25px;
    border-right: 1px solid var(--borderColor);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.homebannerFlexLeftFooter h5 {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 15px;
    /* font-family: var(--tertiaryFont); */
    /* font-family: var(--testFont1); */
    /* font-family: var(--testFont2); */
    font-family: var(--testFont3);
}

.homebannerFlexLeftFooter h6 {
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 400;
    /* text-align: justify; */
    /* font-family: var(--tertiaryFont); */
    /* font-family: var(--testFont1); */
    /* font-family: var(--testFont2); */
    font-family: var(--testFont3);
    /* margin-bottom: 15px; */
}

.headSpan {
    font-size: 50px;
    margin-left: 0px;
}

#overlayHero {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 2;
    cursor: pointer;
    border-radius: 20px;
}

.HowItWorks {
    height: 30px;
    position: absolute;
    top: 5%;
    right: 5%;
    z-index: 2;
}

.callButton {
    padding: 8px 20px;
    /* border: none; */
    background-color: var(--primaryColor);
    border-radius: 5px;
    color: var(--white);
    font-size: 18px;
    cursor: pointer;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
    animation: scaleani 0.7s ease-in-out alternate infinite;
    margin-right: 30px;
    margin-left: 10px;
}
@keyframes scaleani {
    0% {
        transform: scale(1);
    }
    100%{
        transform: scale(1.1);
    }
}

.whatsAppIconBanner,
.callIconBanner {
    font-size: 35px;
    color: var(--white);
    background-color: var(--primaryColor);
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
}

.callIconBanner {
    margin-left: 15px;
}

.homeBannerButton {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media(max-height:800px){
    .homebanner{
        height: 115vh;
    }
    .homebannerFlexRightImg{
        width: 270px;
        height: 440px;
        top: 20%;
    }
    .homeBannerButton{
        margin-bottom: 20px;
    }
    .homebannerFlexLeftHead{
        margin-top: 20px;
    }
    .bannerShade {
        height: 900px;
    }
    .homebannerFlexRight{
        height: 100%;
    }
    .Social{
        bottom: 2%;
        z-index: 10;
        /* background-color: var(--primaryColor);  */
    }
  
}
@media only screen and (max-width: 600px) {
    .homeMobileBanner {
        /* overflow: hidden; */
    }

    .homeMobileBannerHead {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 20px;
        text-align: start;
    }

    .homeMobileBannerHead h1 {
        font-size: 16px;
        font-family: var(--tertiaryFont);
        font-weight: 500;
        opacity: 0.6;
    }

    .homeMobileBannerHead h2 {
        font-family: "Nunito", sans-serif;
        font-weight: 800;
        font-size: 25px;
    }

    .homeMobileBannerVedio {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;
    }

    .homeMobileBannerVedioConatiner {
        display: flex;
        position: relative;
    }

    .homeMobileBannerVedioSection {
        width: 70%;
        height: 370px;
        border-radius: 20px;
        position: relative;
    }

    video {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        object-fit: cover;
    }

    .homeMobileBannerVedio p {
        line-height: 1.5;
        font-size: 15px;
        font-weight: 400;
        text-align: start;
        margin-top: 30px;
        font-family: var(--tertiaryFont);
        text-align: justify;
    }

    .pause {
        font-size: 30px;
        bottom: 4%;
        right: 35%;
    }

    .SocialMob {
        width: 13%;
        height: 60%;
        font-size: 22px;
        display: flex;
        align-items: center;
        color: var(--white);
        justify-content: space-between;
        flex-direction: column;
        padding: 20px 10px;
        background-color: var(--primaryColor);
        position: absolute;
        right: 0%;
        bottom: 0%;
        gap: 3px;
    }

    .certificate {
        margin-top: 25px;
        text-align: start;
    }

    .certificate h2 {
        margin-bottom: 15px;
    }

    .certificateImg img {
        height: 30px;
    }

    .headSpan {
        font-size: 35px;

    }

    .headSpan1 {
        font-size: 35px;
    }

    #overlayHero {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 2;
        cursor: pointer;
        border-radius: 20px;
    }

    .HowItWorks {
        height: 25px;
    }

    .assurance {
        height: 350px;
        margin-top: 30px;
        border-radius: 20px;
        background-image: url('../../../assests/assurance.png');
        background-repeat: no-repeat;
        padding: 15px;
        border: 1px solid var(--borderColor);
        background-color: rgba(0, 0, 0, 0.2);
        overflow: hidden;
        position: relative;
    }

    .assurance img {
        position: absolute;
        top: 0%;
        right: 0%;
        /* transform: rotate(-8deg); */
    }

    .assuranceContent {
        margin-bottom: 15px;
        text-align: start;
        border-bottom: 1px solid var(--borderColor);
    }

    .assuranceContent h2 {
        font-size: 16px;
        font-weight: 600;
        font-family: var(--tertiaryFont);
        margin-bottom: 5px;
    }

    .assuranceContent p {
        font-size: 14px;
        font-weight: 400;
        font-family: var(--tertiaryFont);
        margin-bottom: 15px;
    }

    .homeBannerButton {
        margin-top: 30px;
    }
}