.homeExpand {
    height: 400px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.homeExpandOne {
    width: 100%;
    height: 100px;
}

.homeExpandOneHead {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.homeExpandOneHead h1 {
    font-size: 50px;
    font-family: var(--primaryFont);
    font-weight: 900;
    margin-left: 30px;
}

.homeExpandOneHead span {
    color: var(--primaryColor);
}

.homeExpandOneBody {
    width: 80%;
    height: 0%;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    text-align: start;
    margin-top: 15px;
}

.homeExpandOneBody p {
    font-size: 14px;
    font-weight: 500;
    line-height: 2;
}

.homeExpandList {
    margin-top: 30px;
    line-height: 2;
}

.homeExpandOneBody h4 {
    font-size: 14px;
    font-weight: 600;
}

.homeExpandListItems {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

@media only screen and (max-width: 600px) {
    .homeExpandOneHead img {
        height: 50px;
    }

    .homeExpandOneHead h1 {
        font-size: 35px;
    }

    .homeExpand {
        height: 590px;
    }

    .homeExpandOneBody {
        margin-top: 0px;
        width: 100%;
    }
}