.heading {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.heading h1 {
    font-size: 50px;
    font-family: var(--secondayFont);
    font-weight: 400;
    text-align: start;
}

.heading p {
    width: 73%;
    line-height: 1.5;
    font-size: 16px;
    text-align: left;
    margin-top: 15px;
    margin-bottom: 50px;
    text-align: justify;
}
.txtColor{
    color: var(--primaryColor);
}

@media only screen and (max-width: 600px) {
    .heading h1 {
        font-size: 30px;
        font-family: var(--tertiaryFont);
    }

    .heading p {
        line-height: 1.5;
        width: 100%;
        margin-bottom: 20px;
        font-family: var(--tertiaryFont);
    }

    .marginBottom0 {
        margin-bottom: 0% !important;
    }
}